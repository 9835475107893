<template>
    <loading loader="spinner"
    :active="isLoading"
    :can-cancel="true"
    :is-full-page="true"></loading>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">退稅資料匯入</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <!-- <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center" @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button> -->
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <!-- <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importData(1)">
              匯入退稅資料(Mapping_1)
            </button>
          </div>
          <div class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importData(2)">
              匯入退稅資料(Mapping_2)
            </button>
          </div> -->
          <!-- <div class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importUnitConversion">
              匯入退稅資料品號換算單位
            </button>
          </div> -->
          <!-- <div class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importEstimatedShipment(1)">
              匯入訂單預計出貨明細表
            </button>
          </div> -->
          <!-- <div class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-48 bg-theme-1 text-white" @click="importEstimatedShipment(0)">
              匯入訂單預計出貨明細表(進出口)
            </button>
          </div> -->
          <!-- <div class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importProductNumberPurchaseRecord">
              匯入品號歷史進貨紀錄表
            </button>
          </div> -->
          <!-- <div class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importManufacturerEstimatedPurchase">
              匯入廠商預計進貨表
            </button>
          </div> -->
          <!-- <div v-if="canImport" class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importJobTicke">
              匯入工單資料
            </button>
          </div>
          <div v-if="canImport" class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importInvoice">
              匯入Invoice
            </button>
          </div> -->
          <div class="mt-2 ml-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-32 bg-theme-1 text-white" @click="importAllSheet">
              匯入退稅資料
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import CloudFun, { defineComponent, ref } from '@cloudfun/core'
import VXETable from 'vxe-table'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default defineComponent({
  components: {
    loading: VueLoading,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;
    const checkPermissions = (key: string) => {
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canImport = checkPermissions("Administrator");

    const importData = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        //VXETable.modal.alert(`${file.name}`);
        var pathName = type == 1 ? 'importData/mapping1' : 'importData/mapping2'
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        await model?.dispatch(pathName, { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: "完成匯入",
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importUnitConversion = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        await model?.dispatch('importData/unitConversion', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: "完成匯入",
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importEstimatedShipment = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        formData.append("type", type);
        isLoading.value = true;
        var count = await model?.dispatch('importData/estimatedShipment', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }
    
    const importProductNumberPurchaseRecord = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/productNumberPurchaseRecord', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importManufacturerEstimatedPurchase = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/manufacturerEstimatedPurchase', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importJobTicke = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/manufacturerEstimatedPurchase', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importInvoice = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/manufacturerEstimatedPurchase', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importInvoiceItem = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/manufacturerEstimatedPurchase', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importAllSheet = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/importAllSheet', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    return {
      grid,
      model,
      importData,
      importUnitConversion,
      importEstimatedShipment,
      importProductNumberPurchaseRecord,
      importManufacturerEstimatedPurchase,
      importInvoiceItem,
      importInvoice,
      importJobTicke,
      importAllSheet,
      canImport,
      isLoading,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
</script>
